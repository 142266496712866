// extracted by mini-css-extract-plugin
export var backbuttonTitleWrapper = "foerdermitgliedComponent-module--backbutton-title-wrapper--39f99";
export var companyInfos = "foerdermitgliedComponent-module--company-infos--d5728";
export var companyInfosSymbol = "foerdermitgliedComponent-module--company-infos-symbol--8cc1c";
export var imageColumn = "foerdermitgliedComponent-module--image-column--ebbda";
export var imageWrapper = "foerdermitgliedComponent-module--image-wrapper--7e451";
export var info = "foerdermitgliedComponent-module--info--964e9";
export var link = "foerdermitgliedComponent-module--link--c781d";
export var linkIcon = "foerdermitgliedComponent-module--link-icon--275e6";
export var linkName = "foerdermitgliedComponent-module--link-name--df795";
export var logoWrapper = "foerdermitgliedComponent-module--logo-wrapper--49393";
export var title = "foerdermitgliedComponent-module--title--b346b";